import '../commons/styles/footer.scss'
import './styles/index.scss'
import CommonHeader from '../commons/components/CommonHeader'
import CommonFooter from '../commons/components/CommonFooter'

import QrCodes from '../commons/components/QrCodes.vue'
Vue.config.productionTip = false
new Vue({
  render: h => h(CommonHeader, {
    props: {
      active: 'index'
    }
  }),
}).$mount('#header')

new Vue({
  render: h => h(CommonFooter, {
    props: {
      showLinks: true
    }
  })
}).$mount('#footerCommon')

new Vue({
  render: h => h(QrCodes)
}).$mount('#qrCodes')


